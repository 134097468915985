<template>
  <div>
    <b-row>
      <b-col
        class="font-weight-600 text-center"
        lg="12"
        md="12"
        sm="12"
        xl="12"
        xs="12"
      >
        <span>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            variant="outline-primary"
            @click="handler_click(1)"
          >
            <feather-icon
              class="mr-50"
              icon="CloudIcon"
            />
            Chọn ảnh
          </b-button>
        </span>
        <span>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :hidden="isDeleteImage"
            class="ml-1"
            size="sm"
            variant="outline-danger"
            @click="handler_click(2)"
          >
            <feather-icon
              class="mr-50"
              icon="EyeIcon"
            />
            Xóa ảnh
          </b-button>
        </span>
      </b-col>
      <b-col
        lg="12"
        md="12"
        sm="12"
        xl="12"
        xs="12"
      >
        <b-img
          :src="filePath"
          class="mt-1"
          fluid
          style="width: 100%;height: auto;object-fit: cover;"
        />
      </b-col>
      <library-modal
        :id-library-model="libraryId"
        @handleCloseLibraryModal="handleCloseLibraryModal"
      />
    </b-row>
  </div>
</template>
<script>
import {
  BButton, BCardText, BCol, BFormInput, BFormTextarea, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import LibraryModal from '@/views/ndev-component/library/LibraryModal.vue'
import NViewImage from '@/views/ndev-component/news-data-manager/NViewImage.vue'

export default {
  components: {
    NViewImage,
    LibraryModal,
    BButton,
    BTabs,
    BTab,
    BCardText,
    BRow,
    BCol,
    BFormTextarea,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    rootData: {
      type: Array,
      default: _ => {
      },
    },
    libraryId: {
      type: String,
      default: 'idLibraryModal',
    },
    Nv3ImageId: {
      type: String,
      default: 'Nv3ImageId',
    },
    Nv3ImageType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      nv3ImageModal: {
        id: '',
        name: '',
        content: '',
        position: 0,
        groupId: '',
        groupFatherId: '',
        typeId: '',
        detailFileModels: this.detailFileModels,
      },
      detailFileModels: {
        id: '',
        carCatalogDetailId: '',
        fileId: '',
        filePath: '',
        fileName: '',
        name: '',
        fileType: '',
        fileContent: '',
        filePosition: 0,
      },
      isDeleteImage: false,
      isSaveImage: false,
      loading: false,
      filePath: '',
    }
  },
  watch: {
    rootData: {
      deep: true,
      handler(val) {
        // eslint-disable-next-line prefer-destructuring
        this.nv3ImageModal = val
        // eslint-disable-next-line prefer-destructuring
        this.detailFileModels = val.model[0].detailFileModels[0]
        this.filePath = this.getThumbnail(false, val.fileType, val.filePath)
      },
    },
  },
  // mounted() {
  //   this.func_dataRoot_change(this.rootData)
  // },
  methods: {
    ...mapActions('libraryStore', ['libraryStore_action_ResetBreadCom']),
    handler_click(type) {
      switch (type) {
        case 1: {
          this.libraryStore_action_ResetBreadCom()
          this.$bvModal.show(this.libraryId)
          break
        }
        case 2: {
          this.detailFileModels.filePath = ''
          this.detailFileModels.fileId = ''
          break
        }
        case 3: {
          // Quay về
          this.$bvModal.hide(this.Nv3ImageId)
          break
        }
        case 4: {
          // Lưu dữ liệu
          this.loading = true
          this.nv3ImageModal.model[0].detailFileModels = []
          this.nv3ImageModal.model[0].detailFileModels.push(this.detailFileModels)
          this.$emit('handClick', this.Nv3ImageType, this.nv3ImageModal)
          break
        }
        default: {
          break
        }
      }
    },
    handleCloseLibraryModal(val) {
      // eslint-disable-next-line no-empty
      if (val === null || val === undefined) {

      } else {
        this.detailFileModels = {
          fileName: val.name,
          fileId: val.id,
          filePath: val.filePath,
          fileType: val.fileType,
        }
        this.filePath = this.getThumbnail(true)
      }
      this.$bvModal.hide(this.libraryId)
    },
    // func_dataRoot_change(val) {
    //   this.nv3ImageModel =
    //   this.filePath = this.getThumbnail()
    // },
    getThumbnail(isChooseImage) {
      this.isDeleteImage = false
      if (isChooseImage === true) {
        if (this.detailFileModels.fileType === '2') {
          return this.detailFileModels.filePath
        }
        return `${this.$serverfile}${this.detailFileModels.filePath}`
      }

      if (this.detailFileModels.fileType === '2') {
        return this.detailFileModels.filePath
      }
      return `${this.$serverfile}${this.detailFileModels.filePath}`
    },
    resetModal() {
      this.loading = false
      this.filePath = ''
    },
  },
}
</script>
