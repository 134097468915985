<template>
  <div
    id="v4-Overview"
    class="page-container-table"
  >
    <b-row style="padding-right: 15px; padding-left: 15px;">
      <b-col
        lg="12"
        md="12"
        sm="12"
        xl="12"
        xs="12"
        class="nmt-5px font-weight-600"
      >
        <label>1. Ảnh đại diện</label>
      </b-col>
      <b-col
        lg="12"
        md="12"
        sm="12"
        xl="12"
        xs="12"
      >
        <span>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            variant="outline-primary"
            @click="handler_click(1)"
          >
            <feather-icon
              class="mr-50"
              icon="CloudIcon"
            />
            Chọn ảnh
          </b-button>
        </span>
        <span>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :hidden="isDeleteImage"
            class="ml-1"
            size="sm"
            variant="outline-danger"
            @click="handler_click(2)"
          >
            <feather-icon
              class="mr-50"
              icon="EyeIcon"
            />
            Xem ảnh
          </b-button>
        </span>

        <span style="margin-left: 10px; font-weight: bold">Tên ảnh</span>
      </b-col>
      <b-col
        lg="12"
        md="12"
        sm="12"
        xl="12"
        xs="12"
        class="nmt-5px font-weight-600"
      >
        <label>2. Tiêu đề</label>
      </b-col>
      <b-col
        lg="12"
        md="12"
        sm="12"
        xl="12"
        xs="12"
      >
        <input
          id="txtTitle"
          v-model="detailFileModels.name"
          :placeholder="'Nhập tiêu đề ...'"
          autocomplete="off"
          class="form-control"
        />
      </b-col>
      <b-col
        lg="12"
        md="12"
        sm="12"
        xl="12"
        xs="12"
        class="nmt-5px font-weight-600"
      >

      </b-col>
      <b-col
        lg="12"
        md="12"
        sm="12"
        xl="12"
        xs="12"
        class="nmt-5px font-weight-600"
      >
        <label>3. Nội dung</label>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import MyTabs from '@/components/tabs/MyTabs.vue'
import Overview from '@/views/car-manager/car-catalog/pages/components/Overview.vue'
import Design from '@/views/car-manager/car-catalog/pages/components/Design.vue'
import Price from '@/views/car-manager/car-catalog/pages/components/Price.vue'
import Operate from '@/views/car-manager/car-catalog/pages/components/Operate.vue'
import Intelligent from '@/views/car-manager/car-catalog/pages/components/Intelligent.vue'
import V4DataImage from '@/views/car-manager/car-catalog/pages/V4/V4Data/V4DataImage.vue'

export default {
  components: {
    V4DataImage,
    BButton,
    MyTabs,
    Overview,
    Design,
    Price,
    Operate,
    Intelligent,
  },
  props: {
    dLocalStorage: {
      type: Object,
      default: _ => {
      },
    },
  },
  data() {
    return {
      activeItem: 'overview',
      listTabs: [
        {
          key: 'overview',
          title: 'Tổng quan',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'design',
          title: 'Thiết kế',
          icon: 'CloudLightningIcon',
          isDisabled: false,
        },
        {
          key: 'price',
          title: 'Phiên bản và giá',
          icon: 'SunIcon',
          isDisabled: false,
        },
        {
          key: 'operate',
          title: 'Vận hành',
          icon: 'ListIcon',
          isDisabled: false,
        },
        {
          key: 'intelligent',
          title: 'Intelligent Mobility',
          icon: 'WindIcon',
          isDisabled: false,
        },
      ],
    }
  },
  methods: {
    setActive(tabActive) {
      this.activeItem = tabActive
    },
  },
}
</script>
